<template>
    <v-container grid-list-xl fluid>
        <BoardsError />
        <Charger />
    </v-container>
</template>

<script>
import Charger from "./Charger";
import BoardsError from "@/components/shared/BoardsError.vue";

export default {
    components: { Charger, BoardsError },
}
</script>
